import {
    useAppSelector
} from "../../ReduxStore";
import {
    fetchEventSource
} from "@microsoft/fetch-event-source";
import {
    getConfig
} from "../../RuntimeConfig";
import {
    useEffect
} from "react";

interface EventProviderProps {
    children?: React.ReactNode;
}

export const triggerReconnect = () => {

}

class RetriableError extends Error { }
class FatalError extends Error { }

var eventSource: any = undefined

export default (props: EventProviderProps) => {
    const loginData = useAppSelector(state => state.login);

    useEffect(() => {
        const ctrl = new AbortController();

        if (loginData.login) {
            eventSource = fetchEventSource(getConfig("backendUrl") + "/webapi/v1/event/", {
                keepalive: true,
                signal: ctrl.signal,
                openWhenHidden: true,
                headers: {
                    "Authorization": loginData.token,
                },
                async onopen(response) {
                    if (response.ok) {
                        return; // everything's good
                    } else if (response.status >= 400 && response.status < 500 && response.status !== 429 && response.status !== 401) {
                        // client-side errors are usually non-retriable:
                        throw new FatalError();
                    } else {
                        throw new RetriableError();
                    }
                },
                onmessage(msg) {
                    if (msg.event === "" && msg.data === "") {
                        return;
                    }
                    console.log(`Message ID: ${msg.id} Event: ${msg.event} Data: ${msg.data}`);
                },
                onclose() {
                    // if the server closes the connection unexpectedly, retry:
                    throw new RetriableError();
                },
                onerror(err) {
                    if (err instanceof FatalError) {
                        throw err; // rethrow to stop the operation
                    } else {
                        // do nothing to automatically retry. You can also
                        // return a specific retry interval here.
                    }
                }
            })
        }

        return () => {
            ctrl.abort()
        }
    }, [loginData])

    return (
        <>
            {props.children}
        </>
    )
}
