import "./stylesheets/App.scss";
import {Routes, Route, useParams} from "react-router-dom";
import LandingPage from "./pages/LandingPage";


import EventListener from "./components/EventListener";
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import ShowColors from "./pages/ShowColors";
import {GlobalState} from "./GlobalState";
import {apiConfig} from "./ApiConfig";
import {createBrowserHistory, History} from 'history';
import LoginGate from "./components/LoginGate";
import {
    PageContentFrame,
    PageFrame
} from "./components/PageStructure";
import Login
    from "./components/Login";

export const history: History = createBrowserHistory();

export default function App() {
    return (
        // @ts-ignore
        <HistoryRouter history={history}>
            <div className="App">
                <PageFrame>
                    <LoginGate
                        allowedLocations={[
                            // "/",
                            "/colors",
                        ]}
                        loginElement={
                            <PageContentFrame>
                                <Login/>
                            </PageContentFrame>
                        }
                    >
                        <Routes>
                            <Route key={"default"} path="/" element={<LandingPage />} />
                            <Route key={"colors"} path="/colors" element={<ShowColors />} />
                            <Route key={"asterisk"} path="*" element={<NotFound/>} />
                            <Route key={"404"} path="/404" element={<NotFound/>} />
                            <Route key={"422"} path="/422/:msg" element={<UnprocessableContent/>} />
                        </Routes>
                    </LoginGate>
                </PageFrame>
            </div>
        </HistoryRouter>
    );

    function NotFound() {
        return (
            <div>
              <h1>404 Error - Page Not Found</h1>
              <p>The requested page does not exist.</p>
            </div>
        );
    }

    function UnprocessableContent() {
        const {msg} = useParams();
        return (
            <div>
              <h1>422 Error - {msg}</h1>
            </div>
        );
    }
}
