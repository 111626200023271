// import logo from "./fluidic_logo.svg";

import {
    styled
} from "@mui/material/styles";
import {
    PageContentFrame,
    PageContentSection
} from "../../components/PageStructure";
import Logout
    from "../../components/Logout/Logout";
import Button
    from "@mui/material/Button";
import {
    FormControl,
    Grid,
    useTheme
} from "@mui/material";
import Typography
    from "@mui/material/Typography";
import {
    useAppSelector
} from "../../ReduxStore";

interface LandingPageProps {
    children?: React.ReactNode;
    className?: string;
}

export default styled((props: LandingPageProps): JSX.Element => {
    const theme = useTheme()
    const loginData = useAppSelector(state => state.login);

    return (
        <PageContentFrame className={props.className}>
            <PageContentSection>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant={"h4"} sx={{textAlign: "left"}}>
                            {loginData.user.u_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant={"h4"} sx={{textAlign: "right", color: theme.palette.text.disabled}}>
                            CCS Player passport
                        </Typography>
                    </Grid>
                </Grid>
            </PageContentSection>
            <PageContentSection>
                <Grid container flexDirection={"row"}>
                    <Grid item container flexDirection={"column"} xs={6} sx={{paddingRight: "0.5rem"}}>
                        <Grid item container flexDirection={"row"}>
                            <Grid item xs={6} sx={{textAlign: "right", paddingRight: "2rem"}}>
                                one
                            </Grid>
                            <Grid item xs={6} sx={{textAlign: "left"}}>
                                two
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container flexDirection={"column"} xs={6} sx={{paddingLeft: "0.5rem"}}>
                        <Grid item container flexDirection={"row"}>
                            <Grid item xs={6} sx={{textAlign: "right", paddingRight: "2rem"}}>
                                three
                            </Grid>
                            <Grid item xs={6} sx={{textAlign: "left"}}>
                                four
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PageContentSection>
        </PageContentFrame>
    );
})``
