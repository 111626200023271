import createConfig from "react-runtime-config";

export const {useConfig, useAdminConfig, getConfig} = createConfig({
    namespace: "CCS",
    schema: {
        backendUrl: {
            type: "string",
            description: "URL of backend",
            default: "http://localhost:8000"
        },
        gitHash: {
            type: "string",
            description: 'Git hash the webpack was built from, or "debug build" if not run from azure',
            default: "debug build",
        },
        showVersion: {
            type: "boolean",
            description: "Show the versions in the top right corner",
            default: false,
        },
    },
});
