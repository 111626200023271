import React from "react";
import ReactDOM from "react-dom/client";
import "./stylesheets/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {createTheme, CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import SnackbarCloseButton from "./components/SnackbarCloseButton";
import {SnackbarProvider} from "notistack";
import EventProvider from "./components/EventProvider";
import {useTheme} from "@mui/styles";
import CustomThemeProvider from "./themes/CustomThemeProvider";
import {Provider} from "react-redux";
import {reduxStore} from "./ReduxStore";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
let persistor = persistStore(reduxStore);

export const purge = () => {
    persistor.purge();
};

root.render(
    <Provider store={reduxStore}>
        <PersistGate persistor={persistor} loading={null}>
            <StyledEngineProvider injectFirst={true}>
                <CustomThemeProvider>
                    <CssBaseline enableColorScheme={true} />
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        autoHideDuration={5000}
                        className={"snackbar"}
                    >
                        <EventProvider>
                            <App />
                        </EventProvider>
                    </SnackbarProvider>
                </CustomThemeProvider>
            </StyledEngineProvider>
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
