import {
    styled
} from "@mui/material/styles";
import React
    from "react";
import {
    useAppDispatch
} from "../../ReduxStore";
import {
    setLoggedOut
} from "../../reducers/LoginData";

interface LogoutProps {
    children?: React.ReactNode;
    className?: string;
}
export default styled((props: LogoutProps) => {
    const dispatch = useAppDispatch();

    const childProps = {
        onClick: () => {
            dispatch(setLoggedOut());
        },
    }
    return (
        <span className={props.className}>
            {React.Children.map(props.children, (child) => {
                return React.cloneElement(child as React.ReactElement, childProps)
            })}
        </span>
    )
})``
