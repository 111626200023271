import {
    FormControl,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    styled,
    useTheme
} from "@mui/material";
import Typography
    from "@mui/material/Typography";
import Button
    from "@mui/material/Button";
import IconButton
    from "@mui/material/IconButton";
import {
    Visibility,
    VisibilityOff
} from "@mui/icons-material";
import {
    useState
} from "react";
import {
    DebugApi,
    ResponseError,
    SecurityApi
} from "../../api";
import {
    apiConfig,
    setAccessToken
} from "../../ApiConfig";
import {
    triggerReconnect
} from "../EventProvider";
import {
    setLoggedIn,
    setLoggedOut
} from "../../reducers/LoginData";
import {
    useAppDispatch
} from "../../ReduxStore";

interface LoginProps {
    className?: string;
    setLoginFailed?: (failed: boolean, token: string, user: any) => void;
}

const securityApi = new SecurityApi(apiConfig);
const debugApi = new DebugApi(apiConfig);

export default styled((props: LoginProps) => {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [loginFailed, setLoginFailed] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleSignIn = () => {
        securityApi.getToken(username, password)
            .then((resp) => {
                const token = resp.token_type + " " + resp.access_token
                setAccessToken(token)
                setLoginFailed(false);
                dispatch(setLoggedIn(token, resp.user));
                props.setLoginFailed && props.setLoginFailed(false, token, resp.user);
            })
            .catch((resp: ResponseError) => {
                setLoginFailed(true);
                setPassword("");
                dispatch(setLoggedOut());
            })
    }

    return (
        <div className={props.className} onKeyDown={(e) => {
            if (e.key === "Enter") {
                handleSignIn();
            }
        }}>
            <Typography variant={"h4"}>Log in</Typography>
            <FormControl>
                <InputLabel htmlFor={"username"}>Username</InputLabel>
                <Input value={username} id={"username"} aria-describedby={"Enter username"} onChange={(e) => setUsername(e.target.value)}/>
            </FormControl>
            <FormControl>
                <InputLabel htmlFor={"password"}>Password</InputLabel>
                <Input
                    value={password}
                    id={"password"}
                    type={showPassword ? "text": "password"}
                    aria-describedby={"Enter Password"}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                       <InputAdornment position="end">
                           <IconButton
                               onClick={handleClickShowPassword}
                               onMouseDown={handleMouseDownPassword}
                           >
                               {showPassword ? <VisibilityOff /> : <Visibility />}
                           </IconButton>
                       </InputAdornment>
                    }/>
            </FormControl>
            <FormControl sx={{textAlign: "right"}}>
                <Grid container justifyContent={"end"} spacing={"20px"}>
                    <Grid item>
                        <Button disabled={true}>Sign Up</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleSignIn} variant={"contained"}>Sign In</Button>
                    </Grid>
                </Grid>
            </FormControl>
            { loginFailed &&
                <Grid item sx={{backgroundColor: theme.palette.error.main}}>Login Failed</Grid>
            }
        </div>
    );
})((theme) => {
    return {
        width: "450px",
        height: "320px",
        backgroundColor: theme.theme.palette.background.paper,
        color: theme.theme.palette.text.primary,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };
})
