import {
    styled
} from "@mui/material/styles";
import Box
    from "@mui/material/Box";
import Header
    from "../Header";
import React
    from "react";

interface PageFrameProps {
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
}

export const PageFrame = styled((props: PageFrameProps) => {
    return (
        <div className={props.className}>
            <Header></Header>
            {props.children}
        </div>
    )
})((theme) => {
    return {
        width: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
    };
})

interface PageContentFrameProps {
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
}

export const PageContentFrame = styled((props: PageContentFrameProps) => {
    return (
        <div className={props.className}>
            {props.children}
        </div>
    )
})((theme) => {
    return {
        position: "relative",
        // border: "1px solid red",
        minHeight: "calc(100vh - 120px)",
        paddingTop: "2rem",
    };
})

interface PageContentSectionProps {
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
}

export const PageContentSection = styled((props: PageContentSectionProps) => {
    return (
        <div className={props.className}>
            {props.children}
        </div>
    )
})((theme) => {
    return {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.theme.palette.background.paper,
        padding: "1rem 20px 1rem 20px",
        gap: "0.5rem",
        marginBottom: "1rem",
    }
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {};
