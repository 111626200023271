import {PURGE} from "redux-persist";

interface LoginState {
    login: boolean;
    token: string | undefined;
    user: any;
}

const initialState = {
    login: false,
    token: undefined,
    user: undefined,
} as LoginState;

export const setLoggedIn = (token: string, user: any) => {
    return {
        type: "login/setLoggedIn",
        payload: { token, user },
    };
};

export const setLoggedOut = () => {
    return {
        type: "login/setLoggedOut",
    };
};

function loginReducer(state = initialState, action: any) {
    if (action.type === "login/setLoggedIn") {
        if (!state.login) {
            return {
                token: action.payload.token,
                user: action.payload.user,
                login: true,
            };
        }
    }

    if (action.type === "login/setLoggedOut") {
        if (state.login) {
            return {
                login: false,
                token: undefined,
                user: undefined,
            };
        }
    }

    if (action.type === PURGE) {
        return {
            ...state,
            login: false,
            token: undefined,
        };
    }
    return state;
}

export default loginReducer;
