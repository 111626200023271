/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
} from '../models';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models';

export interface GetTokenRequest {
    username: string;
    password: string;
    grantType?: string;
    scope?: string;
    clientId?: string;
    clientSecret?: string;
}

/**
 * 
 */
export class SecurityApi extends runtime.BaseAPI {

    /**
     * Token
     */
    async getTokenRaw(requestParameters: GetTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling getToken.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling getToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.grantType !== undefined) {
            formParams.append('grant_type', requestParameters.grantType as any);
        }

        if (requestParameters.username !== undefined) {
            formParams.append('username', requestParameters.username as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        if (requestParameters.scope !== undefined) {
            formParams.append('scope', requestParameters.scope as any);
        }

        if (requestParameters.clientId !== undefined) {
            formParams.append('client_id', requestParameters.clientId as any);
        }

        if (requestParameters.clientSecret !== undefined) {
            formParams.append('client_secret', requestParameters.clientSecret as any);
        }

        const response = await this.request({
            path: `/webapi/v1/security/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Token
     */
    async getToken(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getTokenRaw({ username: username, password: password, grantType: grantType, scope: scope, clientId: clientId, clientSecret: clientSecret }, initOverrides);
        return await response.value();
    }

}
