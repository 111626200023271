import {Configuration} from "./api";
import {getConfig} from "./RuntimeConfig";
import NoContentMiddleware from "./NoContentMiddleware";

var accessToken = ""

export const setAccessToken = (token: string) => {
    accessToken = token
}

export const apiConfig = new Configuration({
    basePath: getConfig("backendUrl"),
    credentials: "include",
    middleware: [new NoContentMiddleware()],
    accessToken: async () =>  { return accessToken; },
});
