import React, {useState} from "react";
import getTheme from "./base";
import {ThemeProvider} from "@mui/material";

interface CustomThemeContextType {
    currentTheme: string;
    setTheme: ((name: string) => void) | null;
}

// eslint-disable-next-line no-unused-vars
export const CustomThemeContext = React.createContext<CustomThemeContextType>({
    currentTheme: "light",
    setTheme: null,
});

interface CustomThemeProviderProps {
    children: React.ReactNode;
}

const CustomThemeProvider = (props: CustomThemeProviderProps) => {
    // eslint-disable-next-line react/prop-types
    const {children} = props;

    // Read current theme from localStorage or maybe from an api
    const currentTheme = localStorage.getItem("appTheme") || "dark";

    // State to hold the selected theme name
    const [themeName, _setThemeName] = useState(currentTheme);

    // Retrieve the theme object by theme name
    const theme = getTheme(themeName);

    // Wrap _setThemeName to store new theme names in localStorage
    const setThemeName = (name: string) => {
        localStorage.setItem("appTheme", name);
        _setThemeName(name);
    };

    const contextValue: CustomThemeContextType = {
        currentTheme: themeName,
        setTheme: setThemeName,
    };

    return (
        <CustomThemeContext.Provider value={contextValue}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </CustomThemeContext.Provider>
    );
};

export default CustomThemeProvider;
