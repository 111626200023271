import {createTheme} from "@mui/material";
import {themeCommon} from "./common";

const theme = createTheme({
    ...themeCommon,
    ...{
        palette: {
            mode: "light",
            primary: {
                // light: will be calculated from palette.primary.main,
                main: "#0072ce",
                light: "#67a9f9",
                dark: "#4373a3",
                // dark: will be calculated from palette.primary.main,
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                main: "#d3deea",
                light: "#e3eaf1",
                dark: "#93afca",
            },
            info: {
                main: "#0072ce",
                light: "#67a9f9",
                dark: "#4373a3",
                contrastText: "#ffffff",
            },
            success: {
                main: "#00b100",
                contrastText: "#ffffff",
            },
            warning: {
                main: "#ffb520",
                contrastText: "#ffffff",
            },
            error: {
                main: "#a01320",
                contrastText: "#ffffff",
            },
            background: {
                default: "#e3eaf1",
                paper: "#ffffff",
            },
            text: {
                primary: "#000000",
                secondary: "grey",
            },
            action: {
                disabledBackground: "#d0d0d0",
                disabled: "grey",
            },
        },
    },
});

export default theme;
