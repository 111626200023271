import {red} from "@mui/material/colors";
import {createTheme} from "@mui/material";
import {themeCommon} from "./common";

// Dark theme
const theme = createTheme({
    ...themeCommon,
    ...{
        palette: {
            mode: "dark",
            primary: {
                main: "#c0c0c0",
                // light: "rgb(81, 91, 95)",
                // dark: "rgb(26, 35, 39)",
                contrastText: "#262626",
            },
            secondary: {
                main: "#6d6c6c",
                contrastText: "rgba(0, 0, 0, 0.87)",
            },
            titleBar: {
                main: "#555555",
                contrastText: "#ffffff",
            },
            error: {
                main: "#990000",
            },
            background: {
                default: "#303030",
                paper: "#404040",
            },
            text: {
                primary: "#b0b0b0",
                secondary: "#909090",
                disabled: "#686868",
            },
            success: {
                main: "#1f7a1f",
            },
            info: {
                main: "#00a3cc",
            },
            warning: {
                main: "#cc6600",
            },
        },
    },
});

export default theme;
