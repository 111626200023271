import {
    styled
} from "@mui/material/styles";
import Logout
    from "../Logout/Logout";
import Button
    from "@mui/material/Button";
import {
    useAppSelector
} from "../../ReduxStore";

interface HeaderProps {
    children?: React.ReactNode;
    className?: string,
}
export default styled((props: HeaderProps) => {
    const loginData = useAppSelector((state) => state.login);

    return (
        <div className={props.className} style={{position: "relative"}}>
            Header text/image
            { loginData.login &&
                <div style={{position: "absolute", bottom: "10px", right: "10px"}}>
                    <Logout>
                        <Button>Log Out</Button>
                    </Logout>

                </div>
            }
        </div>
    )
})((theme) => {
    return {
        height: "120px",
        backgroundColor: "#400000",
    };
})
