import {
    styled
} from "@mui/material/styles";
import {
    useLocation
} from "react-router-dom";
import React, {
    useState
} from "react";
import {
    useAppDispatch,
    useAppSelector
} from "../../ReduxStore";
import {
    setAccessToken
} from "../../ApiConfig";
import {
    setLoggedIn,
    setLoggedOut
} from "../../reducers/LoginData";

interface LoginGateProps {
    children: JSX.Element,
    className?: string,
    allowedLocations: string[],
    loginElement: React.ReactNode;
}

export default styled((props: LoginGateProps): JSX.Element => {
    const location = useLocation();
    const loginData = useAppSelector((state) => state.login);

    setAccessToken(loginData.token || "");

    if (loginData.login || props.allowedLocations.includes(location.pathname)) {
        return props.children
    }

    return (
        <>
            {props.loginElement}
        </>
    )
})``
